import VueCookie from 'vue-cookies'

export default {
	namespaced: true,
	state: {
		user: {},
		JWTToken: "",
		RefreshToken: "",
	},
	getters: {
		getuser(state) {
			return state.user;
		},
	},
	mutations: {
		SET_user(state, userInfo) {
			state.user = userInfo;
		},
		SET_token(state, token) {
			state.JWTToken = token;
		},
		SET_refreshToken(state, token) {
			state.RefreshToken = token;
		},
	},
	actions: {
		setUser({ commit }, { payload }) {
			console.log("test payload", commit, payload);
			const { user, refreshToken, token } = payload;
			commit("SET_user", user);
			commit("SET_token", token);
			commit("SET_refreshToken", refreshToken);
			VueCookie.set("accessToken", token);
			VueCookie.set("refreshToken", refreshToken);
		},
	},
};
