export default [
	// *===============================================---*
	// *--------- CONFIG ---- ---------------------------------------*
	// *===============================================---*
	{
		path: "/configuration/key-params",
		name: "app-config-list",
		component: () => import("@/app/views/configs/config-list.vue"),
		meta: {
			action: "manage",
			resource: "all"
		}
	},

	// *===============================================---*
	// *--------- i18n ---- ---------------------------------------*
	// *===============================================---*
	{
		path: "/configuration/i18n/list",
		name: "app-i18n-list",
		component: () => import("@/app/views/i18n/i18n-list.vue"),
		meta: {
			action: "manage",
			resource: "all"
		}
	},
	{
		path: "/configuration/i18n/list/:id",
		name: "app-i18n-detail",
		component: () => import("@/app/views/i18n/i18n-detail.vue"),
		meta: {
			action: "manage",
			resource: "all"
		}
	},
	// *===============================================---*
	// *--------- MAIL ---- ---------------------------------------*
	// *===============================================---*
	{
		path: "/configuration/mail/list",
		name: "app-mail-list",
		component: () => import("@/app/views/mail/mail-list.vue"),
		meta: {
			action: "manage",
			resource: "all"
		}
	},
	{
		path: "/configuration/mail/list/:id",
		name: "app-mail-detail",
		component: () => import("@/app/views/mail/mail-detail.vue"),
		meta: {
			action: "manage",
			resource: "all"
		}
	},
	// *===============================================---*
	// *--------- LIST ---- ---------------------------------------*
	// *===============================================---*
	{
		path: "/configuration/list/object",
		name: "app-list-object",
		component: () => import("@/app/views/list/list-object.vue"),
		meta: {
			action: "manage",
			resource: "all"
		}
	},
	{
		path: "/configuration/list/object/:id",
		name: "app-list-item",
		component: () => import("@/app/views/list/list-item.vue"),
		meta: {
			action: "manage",
			resource: "all"
		}
	},
	// *===============================================---*
	// *--------- ADMIN ---- ---------------------------------------*
	// *===============================================---*
	{
		path: "/admin/list",
		name: "app-admin-list",
		component: () => import("@/app/views/admin/admin-list.vue"),
		meta: {
			action: "manage",
			resource: "all"
		}
	},
	{
		path: "/admin/list/:id",
		name: "app-admin-detail",
		component: () => import("@/app/views/admin/admin-detail.vue"),
		meta: {
			action: "manage",
			resource: "all"
		}
	},
	{
		path: "/admin/user/list",
		name: "app-user-list",
		component: () => import("@/app/views/admin/user-list.vue"),
		meta: {
			action: "manage",
			resource: "all"
		}
	},

	// *===============================================---*
	// *--------- SPEAKER ---- ---------------------------------------*
	// *===============================================---*
	{
		path: "/admin/speaker/list",
		name: "app-speaker-list",
		component: () => import("@/app/views/admin/speaker/speaker-list.vue"),
		meta: {
			action: "manage",
			resource: "all"
		}
	},

	// *===============================================---*
	// *--------- WEBINAR ---- ---------------------------------------*
	// *===============================================---*
	{
		path: "/admin/webinar/list",
		name: "app-webinar-list",
		component: () => import("@/app/views/admin/webinar/webinar-list.vue"),
		meta: {
			action: "read",
			resource: "ACL"
		}
	},

	{
		path: "/admin/webinar/:id",
		name: "app-webinar-detail",
		component: () => import("@/app/views/admin/webinar/webinar-detail.vue"),
		meta: {
			action: "read",
			resource: "ACL"
		}
	},
	{
		path: "/admin/webinar/record/:id",
		name: "app-webinar-record",
		component: () => import("@/app/views/admin/webinar/webinar-record.vue"),
		meta: {
			action: "read",
			resource: "ACL"
		}
	},

	// *===============================================---*
	// *--------- SURVEY ---- ---------------------------------------*
	// *===============================================---*
	{
		path: "/admin/survey/list",
		name: "app-survey-list",
		component: () => import("@/app/views/admin/survey/survey-list.vue"),
		meta: {
			action: "manage",
			resource: "all"
		}
	},

	{
		path: "/admin/survey/:id",
		name: "app-survey-detail",
		component: () => import("@/app/views/admin/survey/survey-detail.vue"),
		meta: {
			action: "read",
			resource: "ACL"
		}
	},

	// *===============================================---*
	// *--------- QUIZZ ---- -----------------------------*
	// *===============================================---*
	{
		path: "/admin/quiz/list",
		name: "app-quiz-list",
		component: () => import("@/app/views/admin/quiz/quiz-list.vue"),
		meta: {
			action: "read",
			resource: "ACL"
		}
	},

	{
		path: "/admin/quiz/:id",
		name: "app-quiz-detail",
		component: () => import("@/app/views/admin/quiz/quiz-detail.vue"),
		meta: {
			action: "read",
			resource: "ACL"
		}
	},

	// *===============================================---*
	// *--------- VIEWER ---- ---------------------------------------*
	// *===============================================---*
	{
		path: "/admin/viewer/list/:id",
		name: "app-viewer-list",
		component: () => import("@/app/views/admin/viewer/viewer-list.vue"),
		meta: {
			action: "read",
			resource: "ACL"
		}
	},
	{
		path: "/admin/viewer/survey/:webinarId/:surveyId",
		name: "app-viewer-survey",
		component: () => import("@/app/views/admin/viewer/survey.vue"),
		meta: {
			action: "read",
			resource: "ACL"
		}
	},
	{
		path: "/admin/viewer/quiz/:webinarId/:surveyId",
		name: "app-viewer-quiz",
		component: () => import("@/app/views/admin/viewer/quiz.vue"),
		meta: {
			action: "read",
			resource: "ACL"
		}
	},
	{
		path: "/admin/test/:id",
		name: "test",
		component: () => import("@/app/views/admin/email/Email.vue"),
		meta: {
			action: "read",
			resource: "ACL",
			contentRenderer: "sidebar-left",
			contentClass: "email-application"
		}
	},

	//my-profil
	{
		path: "/admin/my-profile",
		name: "my-profil",
		component: () => import("@/app/views/admin/my-profile.vue"),
		meta: {
			action: "read",
			resource: "ACL"
		}
	},

	// *===============================================---*
	// *--------- LOGIN ---- ---------------------------------------*
	// *===============================================---*
	{
		path: "/authentification",
		name: "authentification",
		component: () => import("@/app/views/auth/login.vue"),
		meta: {
			layout: "full",
			action: "read",
			resource: "Auth",
			redirectIfLoggedIn: false
		}
	}
];
