export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth',
  },
]

export const adminAbility = [
  {
    action: 'manage',
    subject: 'all',
  },
]

export const speakerAbility = [
					{
						action: "read",
						subject: "ACL",
					},

					// {
					// 	action: "read",
					// 	subject: "ACL",
					// },
					{
						action: "read",
						subject: "Auth",
					},
				];

export const _ = undefined
