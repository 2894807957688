import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import apps from "./routes/apps";
import dashboard from "./routes/dashboard";
import uiElements from "./routes/ui-elements/index";
import pages from "./routes/pages";
import chartsMaps from "./routes/charts-maps";
import formsTable from "./routes/forms-tables";
import others from "./routes/others";
import app from "./routes/app";
//import VueCookie from "vue-cookies";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "authentification" } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...app,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
 
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({ name: "authentification" });
    }

    // If logged in => not authorized
    return next({ name: "misc-not-authorized" });
  }
 
  // // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    return next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  /*
	if (!to.matched.some((record) => record.meta.authNotRequired)) {
		if (!VueCookie.get("accessToken")) {
			console.log('not accesstoken')
			next({
				path: "/authentification",
				query: { redirect: to.fullPath },
			});
		} else {
			console.log('ok accesstoken')
			next();
		}
	} else {
		next();
	} */
  return next();
});
export default router;
