import { createToastInterface } from "vue-toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// Toast Notification Component Styles
import "@core/scss/vue/libs/toastification.scss";

const ToastOptions = {
	hideProgressBar: true,
	closeOnClick: false,
	closeButton: false,
	icon: false,
	timeout: 3000,
	transition: "Vue-Toastification__fade",
};

const Toast = createToastInterface(ToastOptions);

export default {
	install(Vue) {
		Vue.prototype.$toastError = function(text) {
			return Toast({
				component: ToastificationContent,
				props: {
					title: "Notification",
					icon: "AlertTriangleIcon",
					text,
					variant: "danger",
				},
			});
		};

		
	},
};
