import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
// Modules
import ecommerceStoreModule from "@/views/apps/e-commerce/eCommerceStoreModule";
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import user from "./user";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
});

export default new Vuex.Store({
	modules: {
		app,
		appConfig,
		verticalMenu,
		user,
		"app-ecommerce": ecommerceStoreModule,
	},
	plugins: [vuexLocal.plugin],
	strict: process.env.DEV,
});
